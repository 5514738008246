import * as React from "react";
import type { HeadFC, PageProps } from "gatsby";
import BaseLayout from "../layouts/BaseLayout/BaseLayout";
import HomePage from "../components/HomePage/HomePage";
import { useEffect, useState } from "react";
import { Header } from "../components";

const IndexPage: React.FC<PageProps> = () => {
  const [isLadingPage, setIsLadingPage] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsLadingPage(true);
    };

    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  if (!isLadingPage) {
    return null;
  }

  return (
    <>
      <BaseLayout>
        <Header backgroundColor={"#F7F8FA"} />
        <HomePage />
      </BaseLayout>
    </>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <title>Serpnest - Precision SERP Rank Tracker</title>
    <meta
      name="description"
      content={
        "The ultimate SERP rank tracker designed for SEO professionals and individuals alike offering detailed analytics."
      }
    />
    <link rel={"canonical"} href={"https://serpnest.com/"} />
  </>
);
